import { SxProps, Theme } from '@mui/system';

const legendHeight = 283;

const classes = {
  adsContainer: {
    padding: '5px',
    textAlign: 'center',
    minHeight: 308,
  },

  adsContainerImg: {
    maxWidth: '100%',
    height: '100%',
  },
  disabled: {
    pointEvents: 'none',
    cursor: 'default',
  },
  dynamicHeight: (height: number) =>
    ({
      height: (height * 3) / 4 - legendHeight,
    }),
} satisfies Record<
  string,
  SxProps<Theme> | ((height: number) => SxProps<Theme>)
>;

export default classes;
