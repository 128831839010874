import React from 'react';

import { Box } from '@mui/system';
import { Paper } from '@mui/material';

import {
  getTypeOfUser,
  clientAdvertisements,
  Language,
  language,
  TypeOfUser,
} from '@pharmaplan/common';

import { useAppSelector } from '../../../hooks/useAppSelector';
import EnglishAd from '../../../assets/svg/englishAd.svg';

import PharmacyFrenchAd from '../../../assets/svg/frenchAd.svg';
import XmasPromo from '../../../assets/img/Xmas Promo.jpg';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import AdsCarousel from './AdsCarousel';
import classes from './style';

const { en, fr } = Language;
const { pharmacist, pharmacy } = TypeOfUser;

const AdsContainer = () => {
  const { height } = useWindowDimensions();
  const userType = useAppSelector(getTypeOfUser);

  const userLanguage = useAppSelector(language);
  const ads = useAppSelector(clientAdvertisements);

  const { adsContainer, adsContainerImg, dynamicHeight } = classes;

  const adConfig = {
    [pharmacist]: {
      [en]: {
        src: XmasPromo,
      },
      [fr]: {
        src: XmasPromo,
      },
    },
    [pharmacy]: {
      [en]: {
        src: EnglishAd,
      },
      [fr]: {
        src: PharmacyFrenchAd,
      },
    },
  };

  const { src } = adConfig[userType as keyof typeof adConfig][
      userLanguage as keyof (typeof adConfig)['Pharmacist']
  ];

  return (
    <Box sx={[adsContainer]} component={Paper}>
      {ads?.length ? (
        <AdsCarousel ads={ads} height={dynamicHeight(height ?? 1).height} />
      ) : (
        <Box
          component="img"
          sx={[adsContainerImg]}
          src={src}
          alt="PharmaPlan ad"
        />
      )}
    </Box>
  );
};

export default AdsContainer;
