export const ITEMS_PER_PAGE = 50;

export enum PharmacyLegendVariant {
  posted = 'posted',
  requested = 'requested',
  booked = 'booked',
}

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum MonthlyYearlyRecurrence {
  day = 'onDay',
  weekdaysAndMonths = 'weekdaysAndMonths',
}

export enum MonthlyYearlyRecurrenceDropdown {
  onDayOfTheMonth = 'onDay',
  onTheMontheOfTheYear = 'onThe',
  onTheDay = 'onTheDay',
  onTheMonth = 'onTheMonth',
}

export enum Ordinals {
  first = 1,
  second = 2,
  third = 3,
  fourth = 4,
}

export enum SocialLoginType {
  google = 1,
  facebook = 2,
  apple = 3,
}

export enum PharmacistLegendVariant {
  availability = 'availability',
  requested = 'requested',
  booked = 'booked',
  workshift = 'workshift',
  vacation = 'vacation',
}

export enum TypeOfUser {
  pharmacy = 'Pharmacy',
  pharmacist = 'Pharmacist',
  admin = 'Admin',
  superAdmin = 'SuperAdmin',
  pharmacyAdmin = 'CompanyPharmacy',
}

export enum TypeOfUserCapital {
  pharmacy = 'Pharmacy',
  pharmacist = 'Pharmacist',
}

export enum HelpTypes {
  pharmacist = '11',
  pharmacy = '10',
  pharmacyAdmin = '9',
}

export const HelpUserTypes = {
  [HelpTypes.pharmacist]: TypeOfUser.pharmacist,
  [HelpTypes.pharmacy]: TypeOfUser.pharmacy,
};

export enum AdminTypes {
  admin = 1,
  superadmin = 2,
}

export enum RequestStatusTypes {
  notStarted = 'notStarted',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
  pending = 'pending',
}

export enum BottomSheetTypes {
  calendar = 'calendar',
  legendList = 'legendList',
  dashboardPharmacistFilter = 'dashboardPharmacistFilter',
  actionSheet = 'actionSheet',
  reportFilter = 'reportFilter',
  addNote = 'addNote',
  sortSheet = 'sortSheet',
  incomeFilter = 'incomeFilter',
}

export enum CalendarView {
  monthView = 'Month',
  weekRangeView = 'WeekRange',
  weekView = 'Week',
}

export enum PharmacistReportTypes {
  booking = 'booked',
  requested = 'requested',
  myAvailabilities = 'myAvailabilities',
  availableWorkshifts = 'availableWorkshifts',
  incomeReport = 'incomeReport',
  watchlist = 'watchlist',
}

export enum ReportRequestTypes {
  booked = 'booked',
  requested = 'requested',
  availability = 'availability',
  workshift = 'workshift',
  watchlist = 'watchlist',
  vacation = 'vacation',
}

export enum PharmacyBookingStatus {
  booked = 1,
}

export enum PharmacyFilterWorkshifts {
  posted = '0',
  request = '1',
  booked = '2',
  unfulfilled = '3',
}

export enum PharmacyWorkshiftStatus {
  posted = 'posted',
  request = 'requested',
  booked = 'booked',
  unfulfilled = 'unfulfilled',
}

export enum DashboardType {
  calendar = 'calendar',
  map = 'map',
}

export enum CalendarStateKey {
  month = 'month',
  day = 'day',
  week = 'week',
}

export enum PharmacyReportTypes {
  workshiftReport = 'postedWorkshiftReport',
  bookingReport = 'bookingReport',
}

export enum ReportStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Refused = 'Refused',
  Canceled = 'Canceled',
}

export enum recurrenceType {
  Never = 0,
  EveryDay = 1,
  EveryWeekday = 2,
  EveryWeek = 3,
  EveryMonth = 4,
  EveryYear = 5,
  Weekend = 6,
}

export const recurrenceWeekDays: Record<string, string> = {
  sunday: '0',
  monday: '1',
  tuesday: '2',
  wednesday: '3',
  thursday: '4',
  friday: '5',
  saturday: '6',
};

export const frenchCalendar = {
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],
  monthNamesShort: [
    'Jan.',
    'Fév.',
    'Mar.',
    'Avr.',
    'Mai',
    'Jui.',
    'Juil.',
    'Aoû.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Déc.',
  ],
  dayNames: [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ],
  dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
  today: "Aujourd'hui",
};

export enum responseType {
  success = 'Success',
  noData = 'No Data',
  failure = 'Failure',
  network = 'Network Error',
}

export enum errorType {
  error = 1,
  tokenExpired = 2,
  EnforceUpdatePassword = 3,
  handleCustom = 4,
  deletedAccount = 6,
  enforceAppUpdate = 7,
  permissionDenied = 8,
  refreshConfigurations = 9,
  forceLogout = 10,
  noError = 0,
}

export enum RecurrenceActions {
  series = 'series',
  occurence = 'occurence',
}

export enum RecurrenceEvents {
  delete = 'delete',
  update = 'update',
}

export enum UpdateEventTypes {
  single = 'single',
  recurrenceSingle = 'recurrenceSingle',
  recurrenceSeries = 'recurrenceSeries',
}

export enum RefreshScreens {
  calendar = 'calendar',
  reports = 'reports',
}
export enum ReleaseDate {
  // Use this format YYYY-MM-DD
  mobile = '2023-08-28',
  web = '2024-12-13',
}

export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[^&‘“\s])[^\s]{8,20}$/;
export enum ConfirmationTypes {
  booking = 'booking',
  availability = 'availability',
  rejectBooking = 'rejectBooking',
  cancelTAndCModal = 'cancelTAndCModal',
  deleteWorkshift = 'deleteWorkshift',
  updateTiming = 'updateTiming',
  counterOfferConfirm = 'counterOfferConfirm',
  closeChat = 'closeChat',
  makeChannelAvailable = 'makeChannelAvailable',
  counterOfferCancel = 'counterOfferCancel',
  enforceUpdate = 'enforceUpdate',
  continueCreation = 'continueCreation',
  adminContinueCreation = 'adminContinueCreation',
  softwareWarning = 'softwareWarning',
  userDelete = 'userDelete',
  expressBooking = 'expressBooking',
  hidePharmacist = 'hidePharmacist',
  activateUser = 'activateUser',
  deleteEntry = 'deleteEntry',
  takeOwnership = 'takeOwnership',
  publishConfiguration = 'publishConfiguration',
  unPublishConfiguration = 'unPublishConfiguration',
  markIncompatible = 'markIncompatible',
  markCompatible = 'markCompatible',
  unhidePharmacist = 'unhidePharmacist',
  permanentDelete = 'permanentDelete',
  editEmail = 'editEmail',
  createMultipleAvailabilities = 'createMultipleAvailabilities',
  changeEmail = 'changeEmail',
  resetSelection = 'resetSelection',
  sendNotification = 'sendNotification',
  removeWatchlist = 'removeWatchlist',
  vacations = 'vacations',
  availabilitiesConflict = 'availabilitiesConflict',
}

export enum TimeFormat {
  twentyFourHours = 0,
  twelveHours = 1,
}
export enum AvailabilityTypes {
  tempAvailability = 'tempAvailability',
  newVacation = 'newVacation',
}

export enum TypeOfJS {
  string = 'string',
  object = 'object',
}

export const googleGeocode = (
  latitude: number,
  longitude: number,
  apiKey: string
) =>
  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

export enum AllowanceTypes {
  notCovered = '0',
  covered = '1',
  custom = '2',
}

export enum SecondaryInputTypes {
  single = 'single',
  double = 'double',
}

export enum ServiceTypes {
  self = 'self',
  full = 'full',
}

export enum TypeOfSummary {
  workshiftDetails = 'workshiftDetails',
  pharmacist = 'pharmacist',
}

export enum BookingState {
  None = 0,
  PendingWithPharmacist = 1,
  UpdatedTiming = 2,
}

export const AllowancesNames = {
  paidHourlyRate: 'paidHourlyRate',

  travelAllowanceType: 'travelAllowanceType',
  accommodationAllowanceType: 'accommodationAllowanceType',
  mealAllowanceType: 'mealAllowanceType',

  minFreeTravelDistance: 'minFreeTravelDistance',
  travelAllowanceRatePerKm: 'travelAllowanceRatePerKm',
  fixedAccommodationRate: 'fixedAccommodationRate',
  fixedMealRate: 'fixedMealRate',
  emergencyFees: 'emergencyFees',

  // TODO: FOR COUNTER OFFER TEMPORARY
  fixedTravelAmount: 'fixedTravelAmount',
  fixedAccommodationAmount: 'fixedAccommodationAmount',
  hourlyRate: 'hourlyRate',
};

export const AdminAllowanceNames = {
  mealAllowance: 'mealAllowance',
  travelAllowance: 'travelAllowance',
  accommodationAllowance: 'accommodationAllowance',
  emergencyFees: 'emergencyFees',
  paidHourlyRate: 'paidHourlyRate',
  pharmacyHourlyRate: 'pharmacyHourlyRate',
  pharmacistHourlyRate: 'pharmacistHourlyRate',
};

export const SupportedTimezones = {
  EDT: 'EDT',
  CANADA: 'Canada/Eastern',
  EST: 'EST',
};

export const valuesToBeParsed = [
  AllowancesNames.emergencyFees,
  AllowancesNames.fixedAccommodationRate,
  AllowancesNames.fixedMealRate,
  AllowancesNames.travelAllowanceRatePerKm,
  AllowancesNames.paidHourlyRate,
  AllowancesNames.minFreeTravelDistance,
];

export const adminValuesToBeParsed = [
  AdminAllowanceNames.emergencyFees,
  AdminAllowanceNames.mealAllowance,
  AdminAllowanceNames.travelAllowance,
  AdminAllowanceNames.paidHourlyRate,
  AdminAllowanceNames.accommodationAllowance,
];

export const valueCriteria = {
  [AllowancesNames.minFreeTravelDistance]: {
    checkFrom: AllowancesNames.travelAllowanceType,
    shouldBe: AllowanceTypes.custom,
  },
  [AllowancesNames.travelAllowanceRatePerKm]: {
    checkFrom: AllowancesNames.travelAllowanceType,
    shouldBe: AllowanceTypes.custom,
  },
  [AllowancesNames.fixedAccommodationRate]: {
    checkFrom: AllowancesNames.accommodationAllowanceType,
    shouldBe: AllowanceTypes.custom,
  },
  [AllowancesNames.fixedMealRate]: {
    checkFrom: AllowancesNames.mealAllowanceType,
    shouldBe: AllowanceTypes.custom,
  },
};

export const keyToParse = [
  AllowancesNames.paidHourlyRate,
  AllowancesNames.minFreeTravelDistance,
  AllowancesNames.travelAllowanceRatePerKm,
  AllowancesNames.fixedAccommodationRate,
  AllowancesNames.fixedMealRate,
  AllowancesNames.emergencyFees,
];

export const AdminKeyToParse = [
  AdminAllowanceNames.emergencyFees,
  AdminAllowanceNames.paidHourlyRate,
  AdminAllowanceNames.travelAllowance,
  AdminAllowanceNames.mealAllowance,
  AdminAllowanceNames.accommodationAllowance,
  AdminAllowanceNames.pharmacyHourlyRate,
];

export const counterOfferCriteria = () => {
  const {
    [AllowancesNames.minFreeTravelDistance]: _,
    [AllowancesNames.travelAllowanceRatePerKm]: __,
    ...rest
  } = valueCriteria;
  rest[AllowancesNames.fixedTravelAmount] = {
    checkFrom: AllowancesNames.travelAllowanceType,
    shouldBe: AllowanceTypes.custom,
  };
  return rest;
};

export const areValuesDifferent = (
  first: number | string,
  second: number | string
) => {
  return first !== second;
};
export enum TypeOfBill {
  bill = 'bill',
  finalBill = 'finalBill',
  counterOffer = 'counterOffer',
}

export const convertToDecimals = (value: number | undefined) =>
  (value ?? 0) % 1 !== 0 ? value?.toFixed(2) : value ?? 0;

export const volumeRegex = /^\d+(?:\s*-\s*\d+)?$/;

export enum NotificationsType {
  // ALL NEGATIVE CASES
  // ICON: CANCEL CALENDAR | ACTION: GO TO CALENDAR
  PharmacistBookingCancellation = 14,
  PharmacyBookingCancellation = 15,
  BookingRequestCancellation = 3,
  PharmacyRejectedBooking = 5,
  PharmacistRejectedBooking = 7,
  PharmacistRejectedBookedTimings = 13,
  FullServiceBookingCancelled = 25,
  FullServiceRequestRejected = 30,
  FullServiceWatchlistInvalid = 31,

  // ALL DELETE CASES
  // ICON: TRASH CAN | ACTION: GO TO CALENDAR
  WorkshiftDelete = 10,

  // ALL UPDATE CASES
  // ICON: TICKER | ACTION: GO TO WORKSHIFT
  PharmacistReminderApproveBookedTiming = 16,
  PharmacistReminder2ApproveBooking = 17,
  PharmacistReminderApproveBooking = 8,
  WorkshiftUpdate = 9,
  BookedWorkshiftUpdate = 11,
  CounterOfferSubmission = 2,
  FullServiceBookedTimeUpdate = 27,
  FullServiceBookedRateAllowanceUpdate = 28,
  FullServiceBookedUpdate = 29,
  FullServiceWatchlistRateAllowanceUpdate = 32,
  FullServiceWatchlistTimeUpdate = 33,
  FullServiceWatchlistUpdate = 34,
  FullServiceChangedPharmacist = 26,
  FullServicePharmacyBookedReminder = 35,
  FullServicePharmacistBookedReminder = 36,

  // ALL POSITIVE CASES
  // ICON: GREEN TICK | ACTION: GO TO WORKSHIFT
  Unknown = 0,
  BookingRequest = 1,
  PharmacyApprovedBooking = 4,
  PharmacistApprovedBooking = 6,
  PharmacistApprovedBookedTimings = 12,
  FullServiceApproveBooking = 24,
}

export enum NotificationGroups {
  negative = 1,
  positive = 2,
  update = 3,
  delete = 4,
}

export enum PositiveIcons {
  tick = 'tick',
  cancel = 'cancel',
}

export const NotificationsMap = {
  // NEGATIVE
  [NotificationsType.PharmacyBookingCancellation]: NotificationGroups.negative,
  [NotificationsType.PharmacyRejectedBooking]: NotificationGroups.negative,
  [NotificationsType.Unknown]: NotificationGroups.negative,
  [NotificationsType.FullServiceBookingCancelled]: NotificationGroups.negative,
  [NotificationsType.FullServiceRequestRejected]: NotificationGroups.negative,
  [NotificationsType.FullServiceWatchlistInvalid]: NotificationGroups.negative,

  // DELETE
  [NotificationsType.WorkshiftDelete]: NotificationGroups.delete,

  // UPDATE
  [NotificationsType.PharmacistReminderApproveBookedTiming]:
    NotificationGroups.update,
  [NotificationsType.PharmacistReminder2ApproveBooking]:
    NotificationGroups.update,
  [NotificationsType.PharmacistReminderApproveBooking]:
    NotificationGroups.update,
  [NotificationsType.WorkshiftUpdate]: NotificationGroups.update,
  [NotificationsType.BookedWorkshiftUpdate]: NotificationGroups.update,
  [NotificationsType.FullServiceBookedTimeUpdate]: NotificationGroups.update,
  [NotificationsType.FullServiceBookedRateAllowanceUpdate]:
    NotificationGroups.update,
  [NotificationsType.FullServiceBookedUpdate]: NotificationGroups.update,
  [NotificationsType.FullServiceWatchlistRateAllowanceUpdate]:
    NotificationGroups.update,
  [NotificationsType.FullServiceWatchlistTimeUpdate]: NotificationGroups.update,
  [NotificationsType.FullServiceWatchlistUpdate]: NotificationGroups.update,
  [NotificationsType.FullServiceChangedPharmacist]: NotificationGroups.update,

  // POSTITIVE
  [NotificationsType.PharmacistBookingCancellation]:
    NotificationGroups.positive,
  [NotificationsType.PharmacistRejectedBookedTimings]:
    NotificationGroups.positive,
  [NotificationsType.PharmacistRejectedBooking]: NotificationGroups.positive,
  [NotificationsType.BookingRequestCancellation]: NotificationGroups.positive,
  [NotificationsType.BookingRequest]: NotificationGroups.positive,
  [NotificationsType.CounterOfferSubmission]: NotificationGroups.positive,
  [NotificationsType.PharmacyApprovedBooking]: NotificationGroups.positive,
  [NotificationsType.PharmacistApprovedBooking]: NotificationGroups.positive,
  [NotificationsType.PharmacistApprovedBookedTimings]:
    NotificationGroups.positive,
  [NotificationsType.FullServiceApproveBooking]: NotificationGroups.positive,
  [NotificationsType.FullServicePharmacyBookedReminder]:
    NotificationGroups.update,
  [NotificationsType.FullServicePharmacistBookedReminder]:
    NotificationGroups.update,
};

type INotificationIdMapType = Record<
  string,
  {
    idType: string;
    type: PharmacyLegendVariant | PharmacistLegendVariant;
    iconType?: PositiveIcons;
    bookingState: BookingState;
  }
>;

export const NotificationIdMap: INotificationIdMapType = {
  [NotificationsType.BookingRequest]: {
    idType: 'workshiftId',
    type: PharmacyLegendVariant.requested,
    iconType: PositiveIcons.tick,
    bookingState: BookingState.None,
  },
  [NotificationsType.PharmacyApprovedBooking]: {
    idType: 'bookingId',
    type: PharmacistLegendVariant.requested,
    bookingState: BookingState.PendingWithPharmacist,
  },
  [NotificationsType.PharmacistApprovedBooking]: {
    idType: 'bookingId',
    type: PharmacistLegendVariant.booked,
    bookingState: BookingState.None,
  },
  [NotificationsType.PharmacistApprovedBookedTimings]: {
    idType: 'bookingId',
    type: PharmacistLegendVariant.booked,
    bookingState: BookingState.None,
  },
  [NotificationsType.PharmacistReminderApproveBookedTiming]: {
    idType: 'bookingId',
    type: PharmacistLegendVariant.booked,
    bookingState: BookingState.UpdatedTiming,
  },
  [NotificationsType.PharmacistReminder2ApproveBooking]: {
    idType: 'bookingId',
    type: PharmacistLegendVariant.requested,
    bookingState: BookingState.PendingWithPharmacist,
  },
  [NotificationsType.PharmacistReminderApproveBooking]: {
    idType: 'bookingId',
    type: PharmacistLegendVariant.requested,
    bookingState: BookingState.PendingWithPharmacist,
  },
  [NotificationsType.WorkshiftUpdate]: {
    idType: 'workshiftId',
    type: PharmacistLegendVariant.workshift,
    iconType: PositiveIcons.tick,
    bookingState: BookingState.None,
  },
  [NotificationsType.BookedWorkshiftUpdate]: {
    idType: 'bookingId',
    type: PharmacistLegendVariant.booked,
    bookingState: BookingState.UpdatedTiming,
  },
  [NotificationsType.CounterOfferSubmission]: {
    idType: 'workshiftId',
    type: PharmacistLegendVariant.requested,
    iconType: PositiveIcons.tick,
    bookingState: BookingState.None,
  },
  [NotificationsType.PharmacistBookingCancellation]: {
    idType: 'workshiftId',
    iconType: PositiveIcons.cancel,
    type: PharmacyLegendVariant.posted,
    bookingState: BookingState.None,
  },
  [NotificationsType.PharmacistRejectedBookedTimings]: {
    idType: 'bookingId',
    iconType: PositiveIcons.cancel,
    type: PharmacyLegendVariant.booked,
    bookingState: BookingState.None,
  },
  [NotificationsType.PharmacistRejectedBooking]: {
    idType: 'workshiftId',
    iconType: PositiveIcons.cancel,
    type: PharmacyLegendVariant.posted,
    bookingState: BookingState.None,
  },
  [NotificationsType.BookingRequestCancellation]: {
    idType: 'workshiftId',
    iconType: PositiveIcons.cancel,
    type: PharmacyLegendVariant.posted,
    bookingState: BookingState.None,
  },
};

export const showConfirmFor = [
  NotificationsType.PharmacistReminderApproveBooking,
  NotificationsType.PharmacistReminderApproveBookedTiming,
  NotificationsType.PharmacistReminder2ApproveBooking,
  NotificationsType.PharmacyApprovedBooking,
];

export const defaultCenter = {
  lat: 45.5019,
  lng: -73.5674,
};

export const defaultPrefferedRange = 150;
export enum Language {
  en = 'en',
  fr = 'fr',
}

export const totalRatingValue = '5';

export enum ColorScheme {
  auto = 0,
  light = 1,
  dark = 2,
}

export interface UserTypings<T> {
  [TypeOfUser.pharmacy]: T;
  [TypeOfUser.pharmacist]: T;
  [TypeOfUser.admin]?: T | any;
  [TypeOfUser.superAdmin]?: T | any;
  [TypeOfUser.pharmacyAdmin]?: T | any;
}

export const toType = <T,>(val: unknown) => val as keyof T;
export const ToUserTypings = <T,>(obj: UserTypings<T>) => obj;

export const CreateTypes = ToUserTypings({
  Pharmacy: 'pharmacies',
  Pharmacist: 'pharmacists',
});

export enum PendingActivationTypes {
  deletedUsers = 'deletedUsers',
}

export type PendingTabTypes = HelpTypes | PendingActivationTypes;

export enum PendingCancellationTabTypes {
  pendingCancellations = 'pendingCancellations',
  cancelledBookings = 'cancelledBookings',
  rejectedCancellations = 'rejectedCancellations',
}

export enum PendingCancellationStatus {
  Critical = 'Critical',
  Normal = 'Normal',
  Severe = 'Severe',
  Urgent = 'Urgent',
}

export const PendingCancellationDetailsRouteTypes = {
  [PendingCancellationTabTypes.pendingCancellations]: 'pending-cancellations',
  [PendingCancellationTabTypes.cancelledBookings]: 'cancelled-bookings',
  [PendingCancellationTabTypes.rejectedCancellations]: 'rejected-cancellations',
};

export const appName = {
  [ServiceTypes.self]: 'PharmaGap',
  [ServiceTypes.full]: 'PharmaPlan',
};

export enum WorkshiftStatus {
  Posted = 0,
  Requested = 1,
  Booked = 2,
  Unfulfilled = 3,
}

export enum AvailabilitiesReportStatus {
  Free = 'Free',
  Booked = 'Booked',
}

export enum PermissionsOfAdmin {
  Activation = 1,
  Posting = 2,
  ExpressBooking = 3,
  Delete = 4,
  ChangeParameters = 5,
  SetupIncompatibleMatch = 6,
  LogsView = 7,
  BroadcastNotification = 8,
  AdministratorList = 9,
  GlobalSetting = 10,
  PermanentDelete = 11,
  PharmacyAdmin = 12,
  ViewEngagedChat = 13,
}

export enum PermissionsRemovalKeys {
  ExpressBooking = 'ExpressBooking',
  Edit = 'Edit',
  Deactivate = 'Deactivate',
  Hide = 'Hide',
  Delete = 'Delete',
  PermanentDelete = 'PermanentDelete',
  CreateAvailability = 'CreateAvailability',
  CreateMultipleAvailability = 'CreateMultipleAvailability',
}

export enum MiscType {
  All = 'All',
}

export const handleAll = <T,>(value: T | MiscType) =>
  value === MiscType.All ? '' : value;

export enum BroadcastNotificationType {
  email = 1,
  pushNotification = 2,
  message = 3,
}

export const currency = '$';
export const distanceUnit = 'km';

export const perHour = '/Hr';

export enum LogActionTypes {
  create = 1,
  delete = 2,
  update = 3,
  booking = 4,
  cancelBooking = 5,
  updateTime = 6,
  updateRateAndAllowance = 7,
  cancelBookingRequest = 8,
}

export enum PerformerTypes {
  admin = 1,
  superAdmin = 2,
  pharmacyAdmin = 9,
  pharmacy = 10,
  pharmacist = 11,
}

export enum ActivityLogActionTypes {
  Create = 0,
  Update = 1,
  Delete = 2,
  Activate = 3,
  Deactivate = 4,
  Hide = 5,
  Unhide = 6,
  Approve = 7,
  Reject = 8,
  Cancel = 9,
  Upload = 10,
  AppointPharmacyAdministrator = 11,
  RatePharmacist = 12,
  AcceptBookingPharmacist = 13,
}

export enum ActivityLogModuleTypes {
  None = 0,
  Admin = 1,
  Pharmacy = 2,
  Pharmacist = 3,
  Workshift = 4,
  Availability = 5,
  Booking = 6,
  Cancellation = 7,
  NewAccount = 8,
  Incompatibility = 9,
  ExpressBooking = 10,
  Software = 11,
  PharmacistCapacity = 12,
  Banner = 13,
  ContactPreference = 14,
  GlobalSettings = 15,
  SFAdmin = 16,
  AdminNotes = 17,
  BroadcastGroup = 18,
  Broadcast = 19,
  Resume = 20,
  Permanently = 21,
}

export enum LoginType {
  pharmaplan = '0',
}

export const representNullValue = '-';

export enum AddEventType {
  Availabilities = 'Availabilities',
  Vacations = 'Vacations',
}

export const allDayStart = 8;
export const allDayEnd = 22;

export enum SelectionType {
  multiple = 'multiple',
  single = 'single',
}

export enum ChatStatus {
  available,
  engaged,
  assignedToMe,
  away,
}

export enum ChatMessageTypes {
  ChatClosed = 'ChatClosed',
}

export enum PlatformTypes {
  Other,
  Web,
  Mobile,
}

export enum WatchlistedVariants {
  interactive = 'interactive',
  static = 'static',
}

export enum MetaDataKeys {
  users = 'users',
  user_type = 'user_type',
  personnelId = 'personnelId',
  username = 'username',
}

export const imageTypes = {
  jpeg: 'image/jpeg',
  png: 'image/png',
};

